import {Component, inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';

export interface DialogConfirmData {
  text: string;
  title?: string;
  cancel?: string;
  confirm?: string;
}

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  imports: [
    MatButton,
    MatDialogModule,
  ],
})
export class DialogConfirmComponent {
  data = inject<DialogConfirmData>(MAT_DIALOG_DATA);

  public readonly text: string;
  public readonly title: string;
  public readonly cancel: string;
  public readonly confirm: string;

  constructor() {
    const data = this.data;

    this.text = data.text;
    this.title = data.title ?? '';
    this.cancel = data.cancel ?? 'Abbrechen';
    this.confirm = data.confirm ?? 'OK';
  }
}
